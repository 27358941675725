<template>
  <div>
    <div class="mb-4">
      <topbar
        :titulo="$t('ACADEMICO_MATRICULAS.MATRICULA')"
        :breadcrumbs="breadcrumbs"
      >
        <template #header>
          <b-button
            variant="secondary"
            v-b-tooltip.hover
            :title="$t('GERAL.BTN_VOLTAR_EXPLICACAO')"
            @click="irPara()"
          >
            {{ $t('GERAL.VOLTAR') }}
          </b-button>
        </template>
      </topbar>
      <b-tabs content-class="mt-5">
        <!-- Dados gerais do aluno -->
        <b-tab :title="$t('ACADEMICO_MATRICULAS.DADOS_ALUNO')" active>
          <dados-aluno-visualizar :dadosAluno="dadosAlunoPessoa" />
        </b-tab>

        <!-- Dados da matricula -->
        <b-tab :title="$t('ACADEMICO_MATRICULAS.MATRICULA')">
          <visualizar-matricula />
        </b-tab>

        <!-- Endereços -->
        <b-tab :title="$t('ACADEMICO_MATRICULAS.DADOS_ENDERECO')">
          <enderecos
            :visualizar="visualizar"
            :form="dadosAlunoPessoa"
            @criar="openModal"
            @editar="openModal"
          />
        </b-tab>

        <!-- Contatos -->
        <b-tab :title="$t('ACADEMICO_MATRICULAS.DADOS_CONTATO')">
          <componente-contatos
            :visualizar="visualizar"
            :form="dadosAlunoPessoa"
            @criar="openModal"
          />
        </b-tab>

        <!-- Contratos -->
        <b-tab :title="$t('ACADEMICO_MATRICULAS.CONTRATOS')">
          <contratos :contratos="contratosAluno" />
        </b-tab>

        <!-- Responsaveis -->
        <b-tab :title="$t('ACADEMICO_MATRICULAS.DADOS_RESPONSAVEIS')">
          <tabela-vinculos
            :visualizar="visualizar"
            :vinculos="dadosAluno.vinculos"
          />
        </b-tab>
        <b-tab :title="$t('FICHA_SAUDE.FICHA_SAUDE')">
          <ficha-saude
            :fichaSaude="fichaSaude"
            ref="fichaSaudeRef"
            @refs="atualizarRefs"
            :apenasVisualizar="true"
          />
        </b-tab>
      </b-tabs>
    </div>
    <!-- modais -->
    <modal-contato-vinculos
      :exibir="modais.vinculos"
      :form="modais.vinculosDados"
      @fechar="closeModal('vinculos')"
    />

    <modal-contato
      :id="dadosAluno.pessoaId"
      :exibir="modais.contato"
      :form="dadosAlunoPessoa"
      :contato="modais.contatoDados"
      @fechar="closeModal('contato')"
    />

    <modal-endereco
      :exibir="modais.endereco"
      :form="dadosAlunoPessoa"
      :endereco="modais.enderecoDados"
      @fechar="closeModal('endereco')"
    />
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Services:
import ContratosService from '@/common/services/contratos/contratos.service';
import MatriculaService from '@/common/services/matriculas/matriculas.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';
import FichaSaudeService from '@/common/services/ficha-saude/ficha-saude.service';

// Components:
import VisualizarMatricula from './DadosMatriculaVisualizar';
import TabelaVinculos from '@/views/academico/matriculas/components/vinculos/Tabela.vue';
import { FichaSaude } from '@/views/academico/matriculas/components/index';

import {
  DadosAlunoVisualizar,
  Enderecos,
  Contratos,
  ModalEndereco,
} from '@/views/academico/matriculas/components/index';

import ModalContatoVinculos from '@/views/academico/matriculas/components/vinculos/contatos/Modal.vue';

// Componente em Pessoa:
import ComponenteContatos from '@/views/rh/pessoas/components/Contatos.vue';
import ModalContato from '@/views/rh/pessoas/components/contatos/Modal.vue';

export default {
  components: {
    Topbar,
    ComponenteContatos,
    DadosAlunoVisualizar,
    Enderecos,
    ModalContato,
    ModalContatoVinculos,
    ModalEndereco,
    Contratos,
    VisualizarMatricula,
    TabelaVinculos,
    FichaSaude,
  },
  data() {
    return {
      matriculaId: this.$route.params.id,
      contratosAluno: [],
      dadosAluno: [],
      dadosAlunoPessoa: {},
      fichaSaude: {},
      pessoas: [],
      modais: {
        contato: false,
        contatoDados: {},
        endereco: false,
        enderecoDados: {},
        vinculos: false,
        vinculosDados: {},
      },
      visualizar: true,
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_MATRICULAS.ACADEMICO') },
        { titulo: this.$t('ACADEMICO_MATRICULAS.MATRICULA') },
        { titulo: this.$t('GERAL.VISUALIZAR') },
      ],
    };
  },
  mounted() {
    this.getPessoasSemMatricula();

    // Se for aluno existente:
    if (this.matriculaId) {
      this.buscarPorId();
      this.getContratos();
      this.getFichaSaude();
    }
  },
  computed: {
    vinculos() {
      return this.pessoas.filter(
        (item) => item.id !== this.dadosAluno.pessoaId
      );
    },
  },
  methods: {
    atualizarRefs(refs) {
      this.$refs = { ...this.$refs, ...refs };
    },
    atualizarForm() {
      this.$store.dispatch(START_LOADING);
      MatriculaService.salvar(this.dadosAluno)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('MATRICULAS.ATUALIZADO_SUCESSO')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarPorId() {
      this.$store.dispatch(START_LOADING);
      MatriculaService.buscarPorId(this.matriculaId)
        .then(({ data }) => {
          this.dadosAluno = data;
          this.getAluno(this.dadosAluno.pessoaId);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getPessoasSemMatricula() {
      this.$store.dispatch(START_LOADING);
      PessoaService.buscarTodosSemMatricula()
        .then(({ data }) => {
          this.pessoas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getAluno(id) {
      this.$store.dispatch(START_LOADING);
      PessoaService.buscarPorId(id)
        .then(({ data }) => {
          this.dadosAlunoPessoa = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getContratos() {
      this.$store.dispatch(START_LOADING);
      ContratosService.buscarPorMatriculaId(this.matriculaId)
        .then(({ data }) => {
          this.contratosAluno = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getFichaSaude() {
      this.$store.dispatch(START_LOADING);
      FichaSaudeService.buscarPorMatriculaId(this.matriculaId)
        .then(({ data }) => {
          this.fichaSaude = data ? data : {};
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
    irPara() {
      this.$router.push({ name: 'matriculas' });
    },
  },
};
</script>
