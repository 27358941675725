<template>
  <titulo-btns :title="$t('PESSOAS.CONTATO') + '*'" class="mt-4">
    <b-button variant="primary" @click="criar" v-if="!visualizar">{{
      $t('PESSOAS.NOVO_CONTATO')
    }}</b-button>
  </titulo-btns>
</template>
<script>
import TituloBtns from '@/components/headers/TituloBtns.vue';
export default {
  components: { TituloBtns },
  props: {
    visualizar: { type: Boolean, default: false },
  },
  methods: {
    criar() {
      this.$emit('criar');
    },
  },
};
</script>
