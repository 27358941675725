<template>
  <div>
    <div :no-body="visualizar" :class="{ 'p-0': visualizar }">
      <topbar
        v-if="!visualizar"
        :titulo="$t('ACADEMICO_MATRICULAS.EDITAR_MATRICULA')"
        :subtitulo="$t('ACADEMICO_MATRICULAS.EDITAR_MATRICULA_PAGE_DESCRICAO')"
        :breadcrumbs="breadcrumbs"
      >
        <template #header>
          <b-button
            v-if="linkRetornar"
            variant="secondary"
            v-b-tooltip.hover
            :title="$t('GERAL.BTN_VOLTAR_EXPLICACAO')"
            @click="irPara()"
          >
            {{ $t('GERAL.VOLTAR') }}
          </b-button>
        </template>
      </topbar>

      <dados-aluno
        :visualizar="visualizar"
        :form="form"
        :pessoasFiltradas="pessoasFiltradas"
        :idiomas="opcoes.idiomas"
        :periodoLetivo="opcoes.periodoLetivo"
      />

      <vinculos
        v-if="!visualizar"
        :pessoasFiltradas="vinculos"
        :form="form"
        @contato-vinculo="buscarContatoResponsavel"
      />

      <observacoes :form="form" :visualizar="visualizar" />

      <b-row v-if="!visualizar">
        <b-col cols="12" class="text-right">
          <b-button variant="primary" @click="salvar">
            {{ $t('GERAL.SALVAR') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <modal-contato-vinculos
      v-if="!visualizar"
      :exibir="modais.vinculos"
      :form="modais.vinculosDados"
      @fechar="closeModal('vinculos')"
    />
  </div>
</template>

<script>
// Utils & Aux:
import VerificacaoPreenchimento from '@/components/mixins/verificacao-preenchimento';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import modais from '@/common/utils/modais';
import rotas from '@/common/utils/rotas';
import { eVisualizar } from './helper';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Services:
import MatriculaService from '@/common/services/matriculas/matriculas.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';

// Models:
import { Matricula } from '@/common/models';

// Components:
import {
  DadosAluno,
  Vinculos,
  Observacoes,
} from '@/views/academico/matriculas/components/index';
import ModalContatoVinculos from '@/views/academico/matriculas/components/vinculos/contatos/Modal.vue';

export default {
  name: 'EditarMatricula',
  mixins: [VerificacaoPreenchimento],
  components: {
    Topbar,
    DadosAluno,
    Vinculos,
    ModalContatoVinculos,
    Observacoes,
  },
  data() {
    return {
      form: new Matricula({}),
      pessoas: [],
      opcoes: {
        idiomas: [],
        periodoLetivo: [],
      },
      modais: {
        vinculos: false,
        vinculosDados: {},
      },
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO_HOME.PAINEL_ACADEMICO') },
        { titulo: this.$t('ACADEMICO_MATRICULAS.MATRICULA') },
        { titulo: this.$t('ACADEMICO_MATRICULAS.EDITAR_MATRICULA') },
      ],
    };
  },
  mounted() {
    this.buscarMatricula();
    this.buscarPessoasSemMatricula();
    this.buscarIdiomas();
    this.buscarPeriodoLetivo();
  },

  watch: {
    'form.idiomaId': 'obtemDescricaoIdioma',
  },

  computed: {
    linkRetornar() {
      return this.$route?.params?.linkRetornar
        ? this.$route?.params?.linkRetornar
        : 'matriculas';
    },

    visualizar() {
      return eVisualizar(this);
    },

    pessoasFiltradas() {
      this.$store.dispatch(START_LOADING);
      var filtro = this.pessoas;
      this.form.vinculos.forEach((vinculo) => {
        filtro = filtro.filter((item) => item.id !== vinculo.pessoaId);
      });
      this.$store.dispatch(STOP_LOADING);

      return filtro.length > 0
        ? filtro
        : [{ text: 'Carregando alunos', value: undefined }];
    },
    vinculos() {
      return this.pessoas.filter((item) => item.id !== this.form.pessoaId);
    },
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    buscarMatricula() {
      if (!this.$route.params.id) return;
      this.$store.dispatch(START_LOADING);
      MatriculaService.buscarPorId(this.$route.params.id)
        .then(({ data }) => {
          this.form = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    buscarPessoasSemMatricula() {
      this.$store.dispatch(START_LOADING);
      PessoaService.buscarTodosSemMatricula()
        .then(({ data }) => {
          this.pessoas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    buscarIdiomas() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('idiomas')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.idiomas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    buscarPeriodoLetivo() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('periodo-letivo')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.periodoLetivo = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    obterDescontos(listaDescontosAtualizada) {
      this.form.descontos = listaDescontosAtualizada;
    },

    // FUNÇÕES: CRUD:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },

    salvar() {
      if (!this.form.vinculos.length) {
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('ACADEMICO_MATRICULAS.MATRICULA_SEM_VINCULO')
        );
      }

      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      MatriculaService.atualizar(this.form)
        .then(({ data }) => {
          this.salvo = true;
          mensagem.sucesso(this.$t('GERAL.SUCESSO'), data.mensagem);
          this.irPara();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // métodos para salvar descrição no banco:
    obtemDescricaoIdioma(idiomaSelecionado) {
      this.opcoes.idiomas.forEach((idioma) => {
        if (idioma.id == idiomaSelecionado) {
          this.form.idiomaDescricao = idioma.descricao;
        }
      });
    },
    buscarContatoResponsavel(modal, vinculoId) {
      this.$store.dispatch(START_LOADING);
      PessoaService.buscarPorId(vinculoId)
        .then(({ data }) => {
          this.openModal(modal, data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
    // FUNÇÕES AUXILIARES:
    irPara() {
      rotas.irPara(this.linkRetornar, this.form.pessoaId);
    },
  },
};
</script>
