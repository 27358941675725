<template>
  <div>
    <modal
      :exibir="exibir"
      @fechar="fecharModal"
      :titulo="titulo"
      :campos="$refs"
      id="modalAdicionarContato"
    >
      <form-contato
        :form="form"
        :contato="contato"
        @refs="capturarRefs"
        @contato-principal="novoContatoPrincipal"
      />
      <template #modal-footer>
        <div class="d-flex justify-content-between w-100">
          <b-button variant="secondary" @click="fecharModal">
            {{ $t('GERAL.FECHAR') }}
          </b-button>
          <b-button variant="primary" @click="salvar">
            {{ $t('GERAL.SALVAR') }}
          </b-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

import PessoaService from '@/common/services/pessoa/pessoa.service';

import Modal from '@/components/modal/Modal.vue';
import FormContato from '@/views/rh/pessoas/components/contatos/Form.vue';

export default {
  components: {
    Modal,
    FormContato,
  },
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
    contato: { type: Object, default: Object },
    id: { type: String, default: null },
  },
  data() {
    return {
      refs: [],
      contatoPrincipal: false,
    };
  },
  computed: {
    titulo() {
      return this.form.id
        ? this.$t('GERAL.EDITAR')
        : this.$t('PESSOAS.CADASTRO_CONTATO');
    },
  },
  methods: {
    // FUNÇÕES CRUD:
    criarContatoAtribuidoPessoa(contato) {
      const contatoFormatado = {
        principal: contato.principal,
        nome: contato?.nome,
        descricao: contato?.descricao,
        tipoContatoDescricao: contato.tipoContato?.descricao,
        tipoContato: contato.tipoContato,
      };
      this.$store.dispatch(START_LOADING);
      PessoaService.criarContato({ ...contatoFormatado, entidadeId: this.id })
        .then(({ data }) => {
          this.form.contatos = data.data.contatos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    editar() {
      if (!this.validaFormulario()) return;
      this.$store.dispatch(START_LOADING);
      PessoaService.atualizarContato({ ...this.contato, pessoaId: this.id })
        .then(({ data }) => {
          this.form.contatos = data.data.contatos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editarLocalmente(contatos) {
      const { index } = contatos;
      this.form.contatos[index] = contatos;
      this.form.contatos = this.form.contatos.filter((item) => item);
    },
    salvar() {
      if (!this.validaFormulario()) return;
      if (!this.id && this.contato.index == undefined) {
        const contatoFormatado = {
          principal: this.contato.principal,
          nome: this.contato.nome,
          descricao: this.contato.descricao,
          tipoContatoDescricao: this.contato.tipoContatoDescricao,
          tipoContato: this.contato.tipoContato,
        };
        this.form.contatos.push(contatoFormatado);
        this.form.contatos.forEach((element, index) => {
          element.index = index;
          if (
            contatoFormatado.principal === true &&
            element.principal === true &&
            contatoFormatado.index !== element.index
          ) {
            element.principal = false;
          }
        });
        this.fecharModal();
        return;
      } else {
        this.editarLocalmente(this.contato);
      }

      if (this.contato.id) {
        this.editar();
      } else if (this.id) {
        this.criarContatoAtribuidoPessoa(this.contato);
      }
      this.fecharModal();
    },
    novoContatoPrincipal(value) {
      this.contatoPrincipal = value;
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    // FUNÇÕES AUXILIARES:
    validaFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    capturarRefs(refs) {
      this.refs = refs;
    },
  },
};
</script>
