<template>
  <div>
    <titulo @criar="novoContato" :visualizar="visualizar" />
    <tabela @excluir="excluir" :form="form" :visualizar="visualizar" @editar="editar" />
  </div>
</template>

<script>
import mensagem from '@/common/utils/mensagem';
import PessoaService from '@/common/services/pessoa/pessoa.service';
import { STOP_LOADING } from '@/store/Store';
import { START_LOADING } from '@/store/Store';

import Titulo from './contatos/Titulo.vue';
import Tabela from './contatos/Tabela.vue';
export default {
  props: {
    form: { type: Object, default: Object },
    visualizar: { type: Boolean, default: false },
  },
  components: {
    Titulo,
    Tabela,
  },
  methods: {
    novoContato() {
      this.$emit('criar', 'contato');
    },
    editar(contato) {
      this.$emit('editar', 'contato', contato);
    },
    async removerContato(id, contato) {
      this.$store.dispatch(START_LOADING);
      PessoaService.removerContato(id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('PESSOAS.CONTATO_REMOVIDO')
          );
          this.form.contatos.splice(contato.index, 1);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async excluir(contato) {
      const respostaExclusao = await mensagem.confirmacao(
        null,
        this.$t('PESSOAS.CONFIRMAR_EXCLUIR_CONTATO')
      );
      if (respostaExclusao.isConfirmed) {
        if (!contato.item.id) {
          this.form.contatos.splice(contato.index, 1);
          return;
        }
        this.removerContato(contato.item.id, contato);
      }
    },
  },
};
</script>
