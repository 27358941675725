import mensagem from "@/common/utils/mensagem";
import helpers from "@/common/utils/helpers";

export default {
  data() {
    return {
      salvo: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Login" || this.salvo) return next();

    if (helpers.validarAlteracoesEmCampos(this.$refs)) {
      mensagem
        .confirmacao(null, this.$t("GERAL.CONFIRMACAO_SAIR"))
        .then(async (result) => {
          if (result.value) {
            next();
          } else {
            next(false);
          }
        });
    } else {
      next();
    }
  },
};
