import ApiService from "@/common/api/api.service";

const FichaSaudeService = {
    async salvarFicha(fichaSaude) {
        let { data } = await ApiService.post(`FichaSaude`, fichaSaude)
        return data
    },
    async buscarPorMatriculaId(matriculaId) {
        let { data } = await ApiService.get(`FichaSaude?matriculaId=${matriculaId}`);
        return data;
    },
}

export default FichaSaudeService;