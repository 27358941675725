<template>
  <b-table
    :items="form.contatos"
    :fields="campos"
    show-empty
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    responsive
    hover
    striped
  >
    <template #cell(acoes)="item" v-if="!visualizar">
      <div class="flex">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="editar(item)">{{
              $t('GERAL.EDITAR')
            }}</b-dropdown-item>
          <b-dropdown-item @click="excluir(item)">{{
            $t('GERAL.EXCLUIR')
          }}</b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    form: { type: Object, default: Object },
    visualizar: { type: Boolean, default: false },
  },
  data() {
    return {
      campos: [
        {
          key: 'nome',
          label: this.$t('PESSOAS.NOME'),
          formatter: (value) => {
            return value ? value : '-';
          },
        },
        {
          key: 'descricao',
          label: this.$t('PESSOAS.DESCRICAO'),
          formatter: (value) => {
            return value ? value : '-';
          },
        },
        {
          key: 'tipoContatoDescricao',
          label: this.$t('PESSOAS.TIPO_CONTATO'),
          formatter: (value) => {
            return value ? value : '-';
          },
        },
        {
          key: 'principal',
          label: this.$t('PESSOAS.CONTATO_PRINCIPAL'),
          formatter: (value) => {
            return value ? 'Sim' : 'Não';
          },
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    excluir(item) {
      this.$emit('excluir', item);
    },
    editar(item) {
      this.$emit('editar', item.item);
    },
  },
};
</script>

