<template>
  <div class="row">
    <div class="col-12">
      <input-text
        ref="nome"
        v-model="contato.nome"
        :label="$t(`PESSOAS.NOME`)"
        placeholder="Digite um nome"
      />
    </div>
    <div class="col-12">
      <input-select-search
        ref="tipoContatoId"
        :options="opcoes.tipoContato"
        v-model="contato.tipoContato"
        :label="$t('PESSOAS.TIPO_CONTATO')"
        placeholder="Selecione ou digite"
        @input="adicionarDescricao"
        required
      />
    </div>
    <div class="col-12">
      <input-text
        ref="descricao"
        v-model="contato.descricao"
        :label="$t(`PESSOAS.DESCRICAO`)"
        :contactType="descricao.type"
        :placeholder="descricao.msg"
        :disabled="desabilitar.tipoContatoDescricao"
        required
      />
    </div>
    <div class="col-12">
      <b-form-checkbox
        v-model="contato.principal"
        :value="true"
        :unchecked-value="false"
        id="contato-principal"
        name="contato-principal"
      >
        <label for="contato-principal" style="cursor: pointer">{{
          $t('PESSOAS.CONTATO_PRINCIPAL')
        }}</label>
      </b-form-checkbox>
    </div>
  </div>
</template>
<script>
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

import { InputText, InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
    contato: { type: Object, default: Object },
  },
  components: { InputText, InputSelectSearch },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getTipoContato();
    if (this.contato.tipoContato) {
      this.alteraDescricao(this.contato.tipoContato);
    }
  },
  data() {
    return {
      opcoes: {
        tipoContato: [],
      },
      desabilitar: {
        tipoContatoDescricao: true,
      },
      descricao: {
        type: 'Celular',
        msg: 'Selecione um tipo de contato antes',
        opcoes: [
          {
            id: 232,
            type: 'Website',
            msg: this.$t('PESSOAS.TIPO_CONTATO_WEBSITE'),
          },
          {
            id: 246,
            type: 'Residencial',
            msg: this.$t('PESSOAS.TIPO_CONTATO_RESIDENCIAL'),
          },
          {
            id: 247,
            type: 'Comercial',
            msg: this.$t('PESSOAS.TIPO_CONTATO_COMERCIAL'),
          },
          {
            id: 248,
            type: 'Celular',
            descricao: 'Celular teste',
            msg: this.$t('PESSOAS.TIPO_CONTATO_CELULAR'),
          },
          {
            id: 249,
            type: 'E-mail',
            msg: this.$t('PESSOAS.TIPO_CONTATO_EMAIL'),
          },
          { id: 250, type: 'Fax', msg: this.$t('PESSOAS.TIPO_CONTATO_FAX') },
        ],
      },
    };
  },
  computed: {
    contatoPrincipal() {
      return this.ehContatoPrincipal;
    },
  },
  watch: {
    'contato.tipoContato': 'alteraDescricao',
    'form.contatos': 'verificaPrincipal',
  },
  methods: {
    adicionarDescricao(tipoContato) {
      let tipoContatoResultado = this.opcoes.tipoContato.filter(
        (x) => x.id == tipoContato
      );
      this.contato.tipoContatoDescricao = tipoContatoResultado[0].descricao;
      this.contato.descricao = '';
    },
    verificaPrincipal() {
      const contato = this.form.contatos.map((item, index) => ({
        ...item,
        index,
      }));
      this.$emit('contato-principal', contato[contato.length - 1]);
    },
    alteraDescricao(tipoContatoId) {
      if (!this.contato.id && this.contato.index == undefined) {
        this.contato.descricao = '';
      }
      this.descricao.opcoes.forEach((element) => {
        if (element.id == tipoContatoId) {
          this.descricao.msg = element.msg;
          this.descricao.type = element.type;
          this.habilitaCampo('tipoContatoDescricao');
        }
      });
    },
    // FUNÇÕES PARA OBTENÇÃO DE DADOS:
    getTipoContato() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contatos')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.tipoContato = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES
    habilitaCampo(campo) {
      this.desabilitar[campo] = false;
    },
    desabilitaCampo(campo) {
      this.desabilitar[campo] = true;
    },
  },
};
</script>
